var generic = generic || {};
var site = site || {};

(function ($) {

site.emailSignup = {
  templateContainer: $(),

  initEmailSignup : function() {
    var $emailContainerNodes = this.templateContainer;
    if (!$emailContainerNodes.length) {
      return null;
    }

    $emailContainerNodes.each(function() {
      var $emailContainerNode = $(this);
      var srcUrl = window.top.document.location.href;
      var $emailForm         = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
      var $emailSuccess      = $('.email-signup__success', $emailContainerNode);
      var $emailError        = $('.email-signup__error', $emailContainerNode);
      var $emailInput        = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
      var $emailPromotions   = $('input[name="PC_EMAIL_PROMOTIONS"]', $emailContainerNode);
      var $languageIdInput   = $('input[name="LANGUAGE_ID"]', $emailContainerNode);
      var $emailPromotionsLabel   = $('.text--checkbox-label', $emailContainerNode);
      var showErrorList = 0;
      var secondLanguageId = Drupal.settings
      && Drupal.settings.global_js_variables
      && Drupal.settings.global_js_variables.second_language_id;
      var localSuffix = 'francais.esteelauder.ca';
      var isCaFrLocale = generic.cookie('LOCALE') === 'fr_CA' || srcUrl.indexOf(localSuffix) !== -1;

      if (isCaFrLocale && secondLanguageId) {
        $languageIdInput.val(secondLanguageId);
      }

      if (( $emailPromotions.length > 0 ) && ( $emailPromotionsLabel.length > 0 )) {
        var showErrorList = 1;
      }
      var isMobile = !$('body').hasClass('device-pc');
      var colorboxSettings = {
        html: $emailSuccess.html(),
        width: '600px',
        height: '600px',
        className: 'email_signup_sucess_popup'
      };
      if (isMobile) {
        colorboxSettings.width = '100%';
      }
      $emailForm.once('email-signup__form').submit(function(submitEvt) {
        submitEvt.preventDefault();
        $emailSuccess.add($emailError).addClass('hidden');
        $emailInput.removeClass('error');
        $emailPromotions.removeClass('error');
        $emailPromotionsLabel.removeClass('error');
		
      // we want to get all the fields in code because 'serialize' encodes potentially bad emails and decode doesn't decode '+' as spaces        
      var fields = [
          'PC_EMAIL_ADDRESS',
          'PC_EMAIL_PROMOTIONS',
          'PC_EMAIL_PROMOTIONS_PRESENT',
          'LAST_SOURCE',
          'LANGUAGE_ID',
          'COUNTRY_ID',
          'LAST_SOURCE',
          '_SUBMIT',
          'ORIGINAL_SOURCE',
          'redirect_or_text',
          'redirect'
      ];

      var paramObj = {};
      
      // loop through all the fields and get the values
      $.each(fields, function(index, value){
          var formField = $("input[name=" + value + "]", $emailForm);
          
          // for the unchecked checkboxes we want to send empty strings to backend for processing
          if(formField.is(':checkbox') && !formField.prop('checked'))
          {
              paramObj[value] = '';
          }
          
          else
          {
              paramObj[value] = formField.val();
          }
      });

        var form = this;
        // Send the data via a json rpc call

          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [paramObj],
            onSuccess: function (jsonRpcResponse) {
            /** 1st condition satisfies in promotions page where as in footer
            it has three levels so introduced the OR condition */
              if (($(form).closest('.content').length > 0)) {
                /** Only show the success message if the email_signup.success
                handlers haven't already done so */
                if ($('#colorbox').css('display') !== 'block') {
                  $.colorbox(colorboxSettings);
                }
              } else {
                $.when(
                  $(document).triggerHandler('email_signup.success', [jsonRpcResponse])
                ).then(function () {
                  /** Only show the success message if the email_signup.success
                  handlers haven't already done so */
                  if ($('#colorbox').css('display') !== 'block') {
                    $.colorbox({ html: $emailSuccess.html() });
                  }
                });
              }
              $('.email_signup_sucess_popup .email-signup__success-text').on('click', function () {
                $.colorbox.close();
              });
            },
            onFailure: function (jsonRpcResponse) {
              var error = jsonRpcResponse.getError();
              var errorText = error.data.messages[0].text;

              $emailError.text(errorText).removeClass('hidden');
              $emailInput.addClass('error');
            }
          });
      });
    });
  }
};

Drupal.behaviors.emailSignupFormV1 = {
  attach: function (context, settings) {
    site.emailSignup.templateContainer = $('.email-signup', context);
    site.emailSignup.initEmailSignup();
  }
};

})(jQuery);
